import React from "react";
import NotificationItem from "./NotificationItem";
// import { notifications } from "./data";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import { useSelector } from 'react-redux';

const AppNotification = () => {
  const notifications = useSelector(state => state.notifications).sort(function(a,b){return a.time > b.time ? 1 : a.time < b.time ? -1 : 0 });
  
  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h4 className="gx-mb-0">Mantenimientos</h4>
        {/* <i className="gx-icon-btn icon icon-charvlet-down"/> */}
      </div>
      <CustomScrollbars className="gx-popover-scroll">
        <ul className="gx-sub-popover">
          {
            notifications.map((notification, index) =>
              <NotificationItem
                key={index}
                notification={notification}
              />
            )
          }
        </ul>
      </CustomScrollbars>
    </Auxiliary>
  )
};

export default AppNotification;

