import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Informes = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/centroCostos`} />
    <Route
      path={`${match.url}/libroActivos`}
      component={asyncComponent(() => import("./libroActivos/index"))}
    />
    <Route
      path={`${match.url}/centroCostos`}
      component={asyncComponent(() => import("./centroCostos/index"))}
    />
    <Route
      path={`${match.url}/cuentasContables`}
      component={asyncComponent(() => import("./cuentasContables/index"))}
    />
    <Route
      path={`${match.url}/plantillaInventarios`}
      component={asyncComponent(() => import("./plantillaInventarios/index"))}
    />
    <Route
      path={`${match.url}/activosDepreciados`}
      component={asyncComponent(() => import("./activosDepreciados/index"))}
    />
    <Route
      path={`${match.url}/programaMantenimiento`}
      component={asyncComponent(() => import("./programaMantenimiento/index"))}
    />
    <Route
      path={`${match.url}/mantenimientoActivos`}
      component={asyncComponent(() => import("./mantenimientoActivos/index"))}
    />
    //* Oculto
    <Route
      path={`${match.url}/depreFinanciera`}
      component={asyncComponent(() => import("./depreFinanciera/index"))}
    />
    <Route
      path={`${match.url}/depreTributaria`}
      component={asyncComponent(() => import("./depreTributaria/index"))}
    />
    <Route
      path={`${match.url}/movActivoFijo`}
      component={asyncComponent(() => import("./movActivoFijo/index"))}
    />
  </Switch>
);

export default Informes;
