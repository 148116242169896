import React from "react";
import { Button, Form, Input } from "antd";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import md5 from "md5";
import { httpClient, traerTipoMes } from "../util/Api";
import { setTipo } from "../appRedux/actions/Tipo";
import { useDispatch } from "react-redux";

const SignIn = () => {
  const dispatch = useDispatch();
  const { isLoading, error, userLogin } = useAuth();
  const onFinishFailed = (errorInfo) => console.log(errorInfo);

  //userLogin(values);
  const onFinish = async (values) => {
    const { usuario, clave } = values;
    userLogin({
      usuario: usuario,
      clave: md5(clave),
    });
    dispatch(setTipo(await traerTipoMes()));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: "#7ab6de",
      }}
    >
      <div
        style={{
          width: "30%",
          padding: "15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="gx-app-login-main-content"
          style={{
            padding: "30px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <div className="gx-app-logo-content"> */}
          {/* <div className="gx-app-logo-wid" style={{color:"black"}}>
              <h1 style={{color:"#153086"}}>Bienvenido</h1>
              <p><IntlMessages id="app.userAuth.bySigning" /></p>
              <p><IntlMessages id="app.userAuth.getAccount" /></p>
            </div> */}
          <img
            alt="example"
            src="/assets/images/chutana-logo.png"
            style={{ width: "60%", paddingBottom: "25px" }}
          />
          {/* </div> */}
          {/* <div className="gx-app-login-content"> */}
          {/* <div style={{ width: '100%', padding: '15px' }}>
              <h3
                style={{
                  textAlign: 'center',
                  color: '#04225B',
                  fontSize: "x-large"
                }}>
                Iniciar Sesion
              </h3>
            </div> */}
          <Form
            initialValues={{ remember: true }}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="gx-signin-form gx-form-row0"
            style={{ width: "90%" }}
          >
            <Form.Item
              /* initialValue="demo@example.com" */
              initialValue="admin"
              rules={[
                { required: true, message: "El campo usuario es obligatorio" },
              ]}
              name="usuario"
            >
              <Input placeholder="Usuario" />
            </Form.Item>
            <Form.Item
              /* initialValue="demo#123" */
              initialValue="admin"
              rules={[
                { required: true, message: "La contraseña es obligatorio" },
              ]}
              name="clave"
            >
              <Input type="password" placeholder="Clave" />
            </Form.Item>
            {/* <Form.Item>
                <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                <span className="gx-signup-form-forgot gx-link"><IntlMessages
                  id="appModule.termAndCondition"/></span> 
              </Form.Item> */}
            <Form.Item>
              <Button
                style={{
                  backgroundColor: "#153086",
                  color: "#fff",
                  width: "100%",
                }}
                className="gx-mb-0"
                htmlType="submit"
              >
                Ingresar
              </Button>
              {/*  <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages
                id="app.userAuth.signUp"/></Link> */}
            </Form.Item>
            {/* <span className="gx-text-light gx-fs-sm">
                demo user email: 'demo@example.com' and password: 'demo#123'
              </span> */}
          </Form>
          {/* </div> */}
          <AppNotificationContainer loading={isLoading} error={error} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
