import React from "react";
import { Menu } from "antd";
import {
  ClockCircleOutlined,
  CalendarOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";
import SubMenu from "antd/lib/menu/SubMenu";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  const nivel = JSON.parse(localStorage.getItem("token_activo"));

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <br />
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          {nivel.id_nivel == "1" ? (
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              <Menu.Item key="inicio">
                <Link to="/inicio">
                  <i className="icon icon-home" />
                  <span>Inicio</span>
                </Link>
              </Menu.Item>

              <SubMenu
                key="baseDatos"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-folder" />
                    <span>Base de Datos</span>
                  </span>
                }
              >
                <Menu.Item key="baseDatos/activosFijos">
                  <Link to="/baseDatos/activosFijos">
                    <i className="icon icon-crypto" />
                    <span>Activos Fijos</span>
                  </Link>
                </Menu.Item>

                {/* <Menu.Item key="baseDatos/tipoCambio">
                  <Link to="/baseDatos/tipoCambio">
                    <i className="icon icon-crypto" />
                    <span>Tipo de Cambio</span>
                  </Link>
                </Menu.Item> */}

                <Menu.Item key="baseDatos/cuentasContables">
                  <Link to="/baseDatos/cuentasContables">
                    <i className="icon icon-crm" />
                    <span>Cuentas Contables</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="baseDatos/centrosCostos">
                  <Link to="/baseDatos/centrosCostos">
                    <i className="icon icon-crm" />
                    <span>Centros Costos</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="baseDatos/unidades">
                  <Link to="/baseDatos/unidades">
                    <i className="icon icon-listing-dbrd" />
                    <span>Unidades</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="baseDatos/usuarios">
                  <Link to="/baseDatos/usuarios">
                    <i className="icon icon-avatar" />
                    <span>Usuarios</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="baseDatos/proveedores">
                  <Link to="/baseDatos/proveedores">
                    <i className="icon icon-queries" />
                    <span>Proveedores</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="baseDatos/clientes">
                  <Link to="/baseDatos/clientes">
                    <i className="icon icon-orders" />
                    <span>Clientes</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="movimiento"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-widgets" />
                    <span>Movimientos</span>
                  </span>
                }
              >
                <Menu.Item key="movimientos/registroBajas">
                  <Link to="/movimientos/registroBajas">
                    <i className="icon icon-orders" />
                    <span>Registro Bajas</span>
                  </Link>
                </Menu.Item>

                {/* <Menu.Item key="movimientos/retiroTransferencias" disabled>
                  <Link to="/movimientos/retiroTransferencias">
                    <i className="icon icon-crm" />
                    <span>Retiro Transferencias</span>
                  </Link>
                </Menu.Item> */}
              </SubMenu>

              <SubMenu
                key="procesos"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-dasbhoard" />
                    <span>Procesos</span>
                  </span>
                }
              >
                <Menu.Item key="procesos/calcPresupuesto">
                  <Link to="/procesos/calcPresupuesto">
                    <i className="icon icon-extra-components" />
                    <span>Calc. Procesos</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="procesos/cierreMes">
                  <Link to="/procesos/cierreMes">
                    <i className="icon icon-extra-components" />
                    <span>Cierre Mes</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="procesos/cierrePeriodo">
                  <Link to="/procesos/cierrePeriodo">
                    <i className="icon icon-extra-components" />
                    <span>Cierre Periodo</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="mantenimiento"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-calendar" />
                    <span>Mantenimiento</span>
                  </span>
                }
              >
                <Menu.Item key="mantenimiento/programar">
                  <Link to="/mantenimiento/programar">
                    <i className="icon icon-calendar" />
                    <span>Programar</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="informes"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-editor" />
                    <span>Informes</span>
                  </span>
                }
              >
                <Menu.Item
                  key="informes/libroActivos"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/libroActivos">
                    <i className="icon icon-crypto" />
                    <span>Libro de Activos</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="informes/centroCostos"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/centroCostos">
                    <i className="icon icon-crypto" />
                    <span>Centro Costos</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="informes/cuentasContables"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/cuentasContables">
                    <i className="icon icon-crypto" />
                    <span>Cuentas Contables</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="informes/plantillaInventarios"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/plantillaInventarios">
                    <i className="icon icon-crm" />
                    <span>Plantilla Inventarios</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="informes/activosDepreciados"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/activosDepreciados">
                    <ClockCircleOutlined
                      className="icon"
                      style={{ margin: "0 2px 0 0", padding: 0 }}
                    />
                    <span>Activos depreciados</span>
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key="informes/programaMantenimiento"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/programaMantenimiento">
                    <CalendarOutlined
                      className="icon"
                      style={{ margin: "0 2px 0 0", padding: 0 }}
                    />
                    <span>P. Mantenimiento</span>
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key="informes/mantenimientoActivos"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/mantenimientoActivos">
                    <DollarOutlined
                      className="icon"
                      style={{ margin: "0 2px 0 0", padding: 0 }}
                    />
                    <span>Costo de mantenimientos</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="Utilidades"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-map-km-layer" />
                    <span>Utilidades</span>
                  </span>
                }
              >
                <Menu.Item
                  key="utilidades/copiaSeguridad"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/utilidades/copiaSeguridad">
                    <i className="icon icon-upload" />
                    <span>Copia de Seguridad</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          ) : nivel.id_nivel == "2" ? (
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              <SubMenu
                key="informes"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-dasbhoard" />
                    <span>Informes</span>
                  </span>
                }
              >
                <Menu.Item
                  key="informes/libroActivos"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/libroActivos">
                    <i className="icon icon-crypto" />
                    <span>Libro de Activos</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="informes/depreFinanciera"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/depreFinanciera">
                    <i className="icon icon-crypto" />
                    <span>Depre. Financiera</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="informes/depreTributaria"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/depreTributaria">
                    <i className="icon icon-crm" />
                    <span>Depre. Tributaria</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="informes/movActivoFijo"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/movActivoFijo">
                    <i className="icon icon-crm" />
                    <span>Mov. Activo Fijo</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="informes/plantillaInventarios"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/informes/plantillaInventarios">
                    <i className="icon icon-crm" />
                    <span>Plantilla Inventarios</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          ) : nivel.id_nivel == "3" ? (
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              <SubMenu
                key="movimiento"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-dasbhoard" />
                    <span>Movimientos</span>
                  </span>
                }
              >
                <Menu.Item
                  key="movimiento/registroBajas"
                  onClick={() => setSidebarCollapsed(true)}
                >
                  <Link to="/movimiento/registroBajas">
                    <i className="icon icon-crypto" />
                    <span>Registro Bajas</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="movimiento/retiroTransferencias" disabled>
                  <Link to="/movimiento/retiroTransferencias">
                    <i className="icon icon-crm" />
                    <span>Retiro Transferencias</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="procesos"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-dasbhoard" />
                    <span>Procesos</span>
                  </span>
                }
              >
                <Menu.Item key="procesos/calcDepreFinanciera">
                  <Link to="/procesos/calcDepreFinanciera">
                    <i className="icon icon-crypto" />
                    <span>Calc. Depre. Financiera</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="procesos/calcDepreTributaria">
                  <Link to="/procesos/calcDepreTributaria">
                    <i className="icon icon-crm" />
                    <span>Calc. Depre. Tributaria</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="procesos/calcPresupuesto">
                  <Link to="/procesos/calcPresupuesto">
                    <i className="icon icon-crm" />
                    <span>Calc. Procesos</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="procesos/cierreMes">
                  <Link to="/procesos/cierreMes">
                    <i className="icon icon-crm" />
                    <span>Cierre Mes</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="procesos/cierrePeriodo" disabled>
                  <Link to="/procesos/cierrePeriodo">
                    <i className="icon icon-crm" />
                    <span>Cierre Periodo</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="informes"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-dasbhoard" />
                    <span>Informes</span>
                  </span>
                }
              >
                <Menu.Item key="informes/depreFinanciera">
                  <Link to="/informes/depreFinanciera">
                    <i className="icon icon-crypto" />
                    <span>Depre. Financiera</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="informes/depreTributaria">
                  <Link to="/informes/depreTributaria">
                    <i className="icon icon-crm" />
                    <span>Depre. Tributaria</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="informes/movActivoFijo">
                  <Link to="/informes/movActivoFijo">
                    <i className="icon icon-crm" />
                    <span>Mov. Activo Fijo</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="informes/plantillaInventarios">
                  <Link to="/informes/plantillaInventarios">
                    <i className="icon icon-crm" />
                    <span>Plantilla Inventarios</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="servicios"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-dasbhoard" />
                    <span>Servicios</span>
                  </span>
                }
              >
                <Menu.Item key="servicios/ejecucion">
                  <Link to="/servicios/ejecucion">
                    <i className="icon icon-crypto" />
                    <span>Ejecucion</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="servicios/informe">
                  <Link to="/servicios/informe">
                    <i className="icon icon-crm" />
                    <span>Informe</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="servicios/registroProgramacion">
                  <Link to="/servicios/registroProgramacion">
                    <i className="icon icon-crm" />
                    <span>Registro Programacion</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          ) : null}
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
