import { types } from "../types/types";

const initialState = [];

export const NotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setNotifications:
      return action.payload;

    default:
      return state;
  }
};
export default NotificationsReducer;