import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Procesos = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/calcDepreFinanciera`} />
    <Route path={`${match.url}/calcPresupuesto`} component={asyncComponent(() => import('./calcPresupuesto/index'))} />
    <Route path={`${match.url}/cierreMes`} component={asyncComponent(() => import('./cierreMes/index'))} />
    <Route path={`${match.url}/cierrePeriodo`} component={asyncComponent(() => import('./cierrePeriodo/index'))} />
    //* Oculto
    <Route path={`${match.url}/calcDepreFinanciera`} component={asyncComponent(() => import('./calcDepreFinanciera/index'))} />
    <Route path={`${match.url}/calcDepreTributaria`} component={asyncComponent(() => import('./calcDepreTributaria/index'))} />
  </Switch>
);

export default Procesos;
