import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Servicios = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/programar`} />
    <Route path={`${match.url}/programar`} component={asyncComponent(() => import('./registroProgramacion/index'))} />
  </Switch>
);

export default Servicios;
