import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Inicio = ({ match }) => (
    <Switch>
        {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} /> */}
        <Route path={`${match.url}/`} component={asyncComponent(() => import('./dashboard/index'))} />
    </Switch>
);

export default Inicio;
