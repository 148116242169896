import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const BaseDatos = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/activosFijos`} />
    <Route path={`${match.url}/activosFijos`} component={asyncComponent(() => import('./activosFijos/index'))} />
    <Route path={`${match.url}/cuentasContables`} component={asyncComponent(() => import('./cuentasContables/index'))} />
    <Route path={`${match.url}/centrosCostos`} component={asyncComponent(() => import('./centrosCostos/index'))} />
    <Route path={`${match.url}/unidades`} component={asyncComponent(() => import('./unidades/index'))} />
    <Route path={`${match.url}/usuarios`} component={asyncComponent(() => import('./usuarios/index'))} />
    <Route path={`${match.url}/proveedores`} component={asyncComponent(() => import('./proveedores/index'))} />
    <Route path={`${match.url}/clientes`} component={asyncComponent(() => import('./clientes/index'))} />
    //* Oculto
    <Route path={`${match.url}/tipoCambio`} component={asyncComponent(() => import('./tipoCambio/index'))} />
    <Route path={`${match.url}/ubicaciones`} component={asyncComponent(() => import('./ubicaciones/index'))} />
  </Switch>
);

export default BaseDatos;
