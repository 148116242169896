export const types = {
  //Proveedor
  setProveedorRuc: "[SET] Enviar Proveedor Ruc",
  setProveedorRazon: "[SET] Enviar Proveedor Razon Social",
  setProveedorEmail: "[SET] Enviar Proveedor Email",
  setProveedorTelefono: "[SET] Enviar Proveedor Telefono",
  setProveedorContacto: "[SET] Enviar Proveedor Contacto",
  setProveedorDireccion: "[SET] Enviar Proveedor Direccion",
  setProveedorReferencia: "[SET] Enviar Proveedor Referencia",
  setLimpiarDataProveedor: "[SET] Limpiar Data Proveedor",
  //Notificaciones
  setNotifications: "[SET] Guardar Notificaciones",
  //TipoCambio
  setTipo: "[SET] Guardar Tipo de Cambio",
};
