import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Utilidades = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/copiaSeguridad`} />
    <Route
      path={`${match.url}/copiaSeguridad`}
      component={asyncComponent(() => import("./copiaSeguridad/index"))}
    />
  </Switch>
);

export default Utilidades;
