/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router";
import { useAuth } from "../authentication";
import { Modal } from "antd";
import BaseDatos from "./baseDatos";
import Movimiento from "./movimiento";
import Procesos from "./procesos";
import Informes from "./informes";
import Mantenimiento from "./mantenimiento";
import Inicio from "./inicio";
import { httpClient, traerNotificaciones, traerTipoMes } from "../util/Api";
import Moment from "moment";
import { useDispatch } from "react-redux";
import { setNotifications } from "../appRedux/actions/Notifications";
import Utilidades from "./utilidades";
import { setTipo } from "../appRedux/actions/Tipo";

const App = ({ match }) => {
  const [sesionCerrada, setSesionCerrada] = useState(false);
  const { userSignOut } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const nivel = JSON.parse(localStorage.getItem("token_activo"));

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: (event) => {
      if (!sesionCerrada) {
        Modal.warning({
          title: "Tu sesión ha expirado",
          content: (
            <div>
              <p>
                Permaneció mucho tiempo inactivo. <br /> Por favor vuelva a
                Iniciar Sesión.
              </p>
            </div>
          ),
          onOk() {
            localStorage.clear();
            userSignOut(() => {
              history.push("/");
            });
          },
          okText: "Aceptar",
        });
        setSesionCerrada(true);
      }
    },
    onActive: (event) => {},
    onAction: (event) => {},
    debounce: 500,
  });

  useEffect(async () => {
    dispatch(setNotifications(await traerNotificaciones()));
    dispatch(setTipo(await traerTipoMes()));
  }, []);

  return (
    <div
      className="gx-main-content-wrapper"
      style={{ backgroundColor: "#7ab6de" }}
    >
      {nivel.id_nivel == "1" ? (
        <Switch>
          <Route path={`${match.url}inicio`} component={Inicio} />
          <Route path={`${match.url}baseDatos`} component={BaseDatos} />
          <Route path={`${match.url}movimientos`} component={Movimiento} />
          <Route path={`${match.url}procesos`} component={Procesos} />
          <Route path={`${match.url}informes`} component={Informes} />
          <Route path={`${match.url}mantenimiento`} component={Mantenimiento} />
          <Route path={`${match.url}utilidades`} component={Utilidades} />
        </Switch>
      ) : nivel.id_nivel == "2" ? (
        <Switch>
          <Route path={`${match.url}informes`} component={Informes} />
        </Switch>
      ) : nivel.id_nivel == "3" ? (
        <Switch>
          <Route path={`${match.url}movimientos`} component={Movimiento} />
          <Route path={`${match.url}procesos`} component={Procesos} />
          <Route path={`${match.url}informes`} component={Informes} />
          <Route path={`${match.url}mantenimiento`} component={Mantenimiento} />
        </Switch>
      ) : null}
    </div>
  );
};

export default App;
