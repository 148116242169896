import { types } from "../types/types";

const initialState = {};

export const TipoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setTipo:
      return action.payload;

    default:
      return state;
  }
};
export default TipoReducer;
