import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";

const NotificationItem = ({ notification }) => {
  const { title, time } = notification;
  return (
    <li className="gx-media">
      <Link to="/mantenimiento/programar">
        <div className="gx-media-body gx-align-self-center">
          <p className="gx-fs-sm gx-mb-0">{title}</p>
          <span className="gx-meta-date"><small>{Moment(time).format('DD/MM/YYYY')}</small></span>
        </div>
      </Link>
    </li>
  );
};

export default NotificationItem;
