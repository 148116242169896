import axios from "axios";
import moment from "moment";

const fechaI = moment().subtract(1, "days");
const fechaF = moment(fechaI).add(7, "days");

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_URL_BACKEND,
  headers: {
    "Content-Type": "application/json",
  },
});
export const clienteTipoCambio = axios.create({
  baseURL: `http://api.apis.net.pe/v1/`,
  headers: {
    "Content-Type":
      "application/x-www-form-urlencoded; charset=UTF-8;application/json",
  },
});

export const traerTipoMes = async () => {
  const resp = await httpClient.get(`/tipoCambio`);
  return JSON.parse(resp.data.message);
};

export const traerNotificaciones = async () => {
  const resp = await httpClient.post(`/notificacion`, {
    fechaI: fechaI,
    fechaF: fechaF,
  });
  return resp.data.data;
};
