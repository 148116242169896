import { useEffect, useState } from 'react';
import { httpClient } from "../../../util/Api";

export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState('');
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  /* const userLogin = (user, callbackFun) => {
    fetchStart();
    httpClient
      .post('auth/login', user)
      .then(({ data }) => {
        if (data.result) {
          fetchSuccess();
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
          localStorage.setItem('token', data.token.access_token);
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  }; */

  const userLogin = (user, callbackFun) => {
    fetchStart();
    httpClient
      .post('login', user)
      .then(({ data }) => {
        if (data.success) {
          fetchSuccess();
          localStorage.setItem('token_activo', JSON.stringify(data.data));
          getAuthUser(data.data);
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);

        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const userSignup = (user, callbackFun) => {
    fetchStart();
    httpClient
      .post('auth/register', user)
      .then(({ data }) => {
        if (data.result) {
          fetchSuccess();
          localStorage.setItem('token_activo', data.token.access_token);
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    fetchStart();
    fetchSuccess();
    localStorage.removeItem('token_activo');
    setAuthUser(false);
  };

  const getAuthUser = (data) => {
    fetchStart();
    fetchSuccess();
    setAuthUser(data);
  };

  /*  const getAuthUser = () => {
     fetchStart();
     httpClient
       .post('auth/me')
       .then(({ data }) => {
         if (data.user) {
           fetchSuccess();
           setAuthUser(data.user);
         } else {
           fetchError(data.error);
         }
       })
       .catch(function (error) {
         httpClient.defaults.headers.common['Authorization'] = '';
         fetchError(error.message);
       });
   }; */

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    const token = localStorage.getItem('token_activo');
    setAuthUser(JSON.parse(token));
    setLoadingUser(false);
  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
};
